var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation } from '@tanstack/react-query';
import { checkout } from './checkout.api';
import { useAppDispatch, useImpState } from '../../app/hooks';
import { CHECKOUT_APPLICATION_STATE, CHECKOUT_STATUS } from '../apiConstants';
import { setFirstTimeUser, setLoginFlow, setShowSignUp } from '../../app/loginSlice';
import { MODAL_ENUM, setLoading, setShowModal } from '../../app/applicationScreenSlice';
import { showError } from '../api';
import { ANALYTICS } from '../../firebase/firebase';
import { EVENT, PKG } from '../../analytics/analyticsConsts';
import useLogin from './useLogin';
import { setConsumerUUID } from '../../app/consumerInfoSlice';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
// mutation that calls checkout, and then conditionally login
function useCheckoutAndLogin() {
    var _a = useImpState(), merchantData = _a.merchantData, appScreenState = _a.appScreenState, loginState = _a.loginState;
    var dispatch = useAppDispatch();
    var loginMutation = useLogin();
    return useMutation({
        mutationFn: function (params) {
            return checkout({
                phone: params.phone,
                productUUID: merchantData.productUUID,
            });
        },
        onSettled: function () {
            dispatch(setLoading(false));
        },
        onSuccess: function (data, variables) {
            switch (data.status) {
                case CHECKOUT_STATUS.NEW_USER: {
                    dispatch(setShowSignUp(true));
                    return;
                }
                case CHECKOUT_STATUS.PRESIGNUP: {
                    if (!data.applicationState) {
                        dispatch(setShowSignUp(true));
                        return;
                    }
                    break;
                }
                default: {
                    break;
                }
            }
            // if user is using an in store device AND already has the card, we prevent login
            if (appScreenState.inStoreDevice &&
                data.applicationState === CHECKOUT_APPLICATION_STATE.APPROVED) {
                dispatch(setShowModal(MODAL_ENUM.showWelcomeBackInStoreModal));
                return;
            }
            // call login
            loginMutation.mutate({ phone: variables.phone }, {
                onSettled: function () {
                    dispatch(setLoading(false));
                },
                onSuccess: function (data) {
                    if (data.nextStep) {
                        dispatch(setLoginFlow(__assign(__assign({}, loginState.loginFlow), { loginError: false })));
                        dispatch(setConsumerUUID(data.consumerUUID));
                        if (data.nextStep === 'verifyOTP') {
                            dispatch(setShowModal(MODAL_ENUM.showLoginModal));
                            dispatch(setScreen(SCREEN.SERVICE_OTPSCREEN));
                        }
                        if (data.nextStep === 'verifyPIN') {
                            dispatch(setFirstTimeUser(false));
                            dispatch(setShowModal(MODAL_ENUM.showLoginModal));
                            dispatch(setScreen(SCREEN.PIN));
                        }
                    }
                },
                onError: function (err) {
                    if (err.code === '201') {
                        dispatch(setScreen(SCREEN.ACCOUNT_LOCKED));
                        return;
                    }
                    dispatch(setLoginFlow(__assign(__assign({}, loginState.loginFlow), { loginError: true })));
                },
            });
        },
        onError: function (err) {
            ANALYTICS.logEvent(EVENT.ERROR_VIEWED, {
                error_type: err.message,
            });
            ANALYTICS.funcError(PKG, 'phone tab full number > checkoutError', JSON.stringify(err));
            showError(dispatch);
        },
    });
}
export default useCheckoutAndLogin;
